import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

// Assets
import MealsAndBenefitsLogo from 'assets/images/repas-et-bienfaits.png';
import CompanionHomeLogo from 'assets/images/companionhome.png';

// Components
import Menu from '../menu';

// Views
import Avatar from './avatar';

// CSS
import styles from './Header.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- HEADER ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Header({ authenticated = false } : { authenticated: boolean }) {

    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <>
            <Grid
                container
                alignItems="center"
                paddingX={1}
                className={styles.header}
            >
                <div className={styles.ellipse} />
                <Grid
                    item
                    width={56}
                >
                    {authenticated && (
                        <Tooltip title="Menu">
                            <IconButton onClick={() => setOpenMenu(true)}>
                                <MenuIcon style={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
                <Grid
                    item xs
                    textAlign="center"
                >
                    {window.origin.includes('repasetbienfaits') ? (
                        <img
                            alt="meals-and-benefits"
                            src={MealsAndBenefitsLogo}
                            onClick={() => navigate(authenticated ? '/app' : '/')}
                            className={styles.logo}
                        />
                    ) : (
                        <img
                            alt="Compani'On Home"
                            src={CompanionHomeLogo}
                            onClick={() => navigate(authenticated ? '/app' : '/')}
                            className={styles.logo}
                            style={{ maxHeight: 48 }}
                        />
                    )}
                </Grid>
                {authenticated && (
                    <Grid
                        item
                        width={56}
                    >
                        <Avatar />
                    </Grid>
                )}
            </Grid>
            {authenticated && (
                <Menu
                    open={openMenu}
                    onClose={() => setOpenMenu(false)}
                />
            )}
        </>
    );
}
