import React from 'react';

// MUI
import { CircularProgress } from '@mui/material';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- SPINNER ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const Spinner = () => {
    return (
        <div
            className="flex-direction-column"
            style={{ flex: 1, padding: 24 }}
        >
            <CircularProgress />
        </div>
    );
};

export default Spinner;
