import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Services
import { getPermissions } from 'services/storage';

// Views
import { RequirePerm } from '../index';

/* * *
* Lazy Imports
*/

// Meal Questionnaires
const MealQuestionnaires = lazy(() => import('../../../views/authenticated/meal-questionnaires'));
const CreateMealQuestionnaire = lazy(() => import('../../../views/authenticated/meal-questionnaires/create-meal-questionnaire'));
const UpdateMealQuestionnaire = lazy(() => import('../../../views/authenticated/meal-questionnaires/update-meal-questionnaire'));

// Interventions
const Intervention = lazy(() => import('../../../views/authenticated/interventions/intervention'));
const UpdateIntervention = lazy(() => import('../../../views/authenticated/interventions/update-intervention'));

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ MEAL QUESTIONNAIRE ROUTES ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function InterventionRoutes() {

    const permissions = getPermissions() || [];

    return (
        <Routes>
            <Route index element={<MealQuestionnaires />} />
            <Route path=":interventionId">
                <Route index element={<RequirePerm perm={permissions.includes('interventions.view')}><Intervention /></RequirePerm>} />
                <Route path="update" element={<UpdateIntervention />} />
                <Route path="create-meal-questionnaire" element={<RequirePerm perm={permissions.includes('interventions.update')}><CreateMealQuestionnaire /></RequirePerm>} />
                <Route path="update-meal-questionnaire/:mealQuestionnaireId" element={<RequirePerm perm={permissions.includes('interventions.update')}><UpdateMealQuestionnaire /></RequirePerm>} />
            </Route>
        </Routes>
    );
}
