import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Services
import { getPermissions } from 'services/storage';

// Views
import { RequirePerm } from '../index';

/* * *
* Lazy Imports
*/

const Management = lazy(() => import('../../../views/authenticated/management'));

// Companies
const Companies = lazy(() => import('../../../views/authenticated/management/companies'));
const Company = lazy(() => import('../../../views/authenticated/management/companies/company'));
const CreateCompany = lazy(() => import('../../../views/authenticated/management/companies/create-company'));
const UpdateCompany = lazy(() => import('../../../views/authenticated/management/companies/update-company'));

// Users
const Users = lazy(() => import('../../../views/authenticated/management/users'));
const User = lazy(() => import('../../../views/authenticated/management/users/user'));
const CreateUser = lazy(() => import('../../../views/authenticated/management/users/create-user'));
const UpdateUser = lazy(() => import('../../../views/authenticated/management/users/update-user'));

// Interventions
const Interventions = lazy(() => import('../../../views/authenticated/interventions'));
const Intervention = lazy(() => import('../../../views/authenticated/interventions/intervention'));
const CreateIntervention = lazy(() => import('../../../views/authenticated/interventions/create-intervention'));
const UpdateIntervention = lazy(() => import('../../../views/authenticated/interventions/update-intervention'));

// Meal Questionnaires
const MealQuestionnaires = lazy(() => import('../../../views/authenticated/meal-questionnaires'));
const CreateMealQuestionnaire = lazy(() => import('../../../views/authenticated/meal-questionnaires/create-meal-questionnaire'));
const UpdateMealQuestionnaire = lazy(() => import('../../../views/authenticated/meal-questionnaires/update-meal-questionnaire'));

// Question Categories
const QuestionCategories = lazy(() => import('../../../views/authenticated/management/question-categories'));
const QuestionCategory = lazy(() => import('../../../views/authenticated/management/question-categories/question-category'));
const CreateQuestionCategory = lazy(() => import('../../../views/authenticated/management/question-categories/create-question-category'));
const UpdateQuestionCategory = lazy(() => import('../../../views/authenticated/management/question-categories/update-question-category'));

// Questions
const Questions = lazy(() => import('../../../views/authenticated/management/questions'));
const Question = lazy(() => import('../../../views/authenticated/management/questions/question'));
const CreateQuestion = lazy(() => import('../../../views/authenticated/management/questions/create-question'));
const UpdateQuestion = lazy(() => import('../../../views/authenticated/management/questions/update-question'));

// Questionnaire Models
const QuestionnaireModels = lazy(() => import('../../../views/authenticated/management/questionnaire-models'));
const QuestionnaireModel = lazy(() => import('../../../views/authenticated/management/questionnaire-models/questionnaire-model'));
const CreateQuestionnaireModel = lazy(() => import('../../../views/authenticated/management/questionnaire-models/create-questionnaire-model'));
const UpdateQuestionnaireModel = lazy(() => import('../../../views/authenticated/management/questionnaire-models/update-questionnaire-model'));

// Weak Signal Categories
const WeakSignalCategories = lazy(() => import('../../../views/authenticated/management/weak-signal-categories'));
const WeakSignalCategory = lazy(() => import('../../../views/authenticated/management/weak-signal-categories/weak-signal-category'));
const CreateWeakSignalCategory = lazy(() => import('../../../views/authenticated/management/weak-signal-categories/create-weak-signal-category'));
const UpdateWeakSignalCategory = lazy(() => import('../../../views/authenticated/management/weak-signal-categories/update-weak-signal-category'));

// Weak Signals
const WeakSignals = lazy(() => import('../../../views/authenticated/management/weak-signals'));
const WeakSignal = lazy(() => import('../../../views/authenticated/management/weak-signals/weak-signal'));
const CreateWeakSignal = lazy(() => import('../../../views/authenticated/management/weak-signals/create-weak-signal'));
const UpdateWeakSignal = lazy(() => import('../../../views/authenticated/management/weak-signals/update-weak-signal'));

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGEMENT ROUTES ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ManagementRoutes() {

    const permissions = getPermissions() || [];

    return (
        <Routes>
            <Route index element={<Management />} />
            {/* -------------------- COMPANIES -------------------- */}
            <Route path="companies">
                <Route index element={<RequirePerm perm={permissions.includes('companies.viewAny')}><Companies /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('companies.create')}><CreateCompany /></RequirePerm>} />
                <Route path=":id">
                    <Route index element={<RequirePerm perm={permissions.includes('companies.view')}><Company /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('companies.update')}><UpdateCompany /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- USERS -------------------- */}
            <Route path="users">
                <Route index element={<RequirePerm perm={permissions.includes('users.viewAny')}><Users /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('users.create')}><CreateUser /></RequirePerm>} />
                <Route path=":userId">
                    <Route index element={<RequirePerm perm={permissions.includes('users.view')}><User /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('users.update')}><UpdateUser /></RequirePerm>} />
                    <Route path="interventions">
                        <Route index element={<RequirePerm perm={permissions.includes('interventions.viewAny')}><Interventions /></RequirePerm>} />
                        <Route path="create" element={<RequirePerm perm={permissions.includes('interventions.create')}><CreateIntervention /></RequirePerm>} />
                        <Route path=":interventionId">
                            <Route index element={<RequirePerm perm={permissions.includes('interventions.view')}><Intervention /></RequirePerm>} />
                            <Route path="update" element={<RequirePerm perm={permissions.includes('interventions.update')}><UpdateIntervention /></RequirePerm>} />
                            <Route path="create-meal-questionnaire" element={<RequirePerm perm={permissions.includes('interventions.update')}><CreateMealQuestionnaire /></RequirePerm>} />
                            <Route path="update-meal-questionnaire/:mealQuestionnaireId" element={<RequirePerm perm={permissions.includes('interventions.update')}><UpdateMealQuestionnaire /></RequirePerm>} />
                        </Route>
                    </Route>
                    <Route path="meal-questionnaires" element={<RequirePerm perm={permissions.includes('users.view')}><MealQuestionnaires /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- QUESTION CATEGORIES -------------------- */}
            <Route path="question-categories">
                <Route index element={<RequirePerm perm={permissions.includes('questionCategories.viewAny')}><QuestionCategories /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('questionCategories.create')}><CreateQuestionCategory /></RequirePerm>} />
                <Route path=":id">
                    <Route index element={<RequirePerm perm={permissions.includes('questionCategories.view')}><QuestionCategory /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('questionCategories.update')}><UpdateQuestionCategory /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- QUESTIONS -------------------- */}
            <Route path="questions">
                <Route index element={<RequirePerm perm={permissions.includes('questions.viewAny')}><Questions /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('questions.create')}><CreateQuestion /></RequirePerm>} />
                <Route path=":id">
                    <Route index element={<RequirePerm perm={permissions.includes('questions.view')}><Question /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('questions.update')}><UpdateQuestion /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- QUESTIONNAIRE MODELS -------------------- */}
            <Route path="questionnaire-models">
                <Route index element={<RequirePerm perm={permissions.includes('questionnaireModels.viewAny')}><QuestionnaireModels /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('questionnaireModels.create')}><CreateQuestionnaireModel /></RequirePerm>} />
                <Route path=":id">
                    <Route index element={<RequirePerm perm={permissions.includes('questionnaireModels.view')}><QuestionnaireModel /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('questionnaireModels.update')}><UpdateQuestionnaireModel /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- WEAK SIGNAL CATEGORIES -------------------- */}
            <Route path="weak-signal-categories">
                <Route index element={<RequirePerm perm={permissions.includes('weakSignalCategories.viewAny')}><WeakSignalCategories /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('weakSignalCategories.create')}><CreateWeakSignalCategory /></RequirePerm>} />
                <Route path=":id">
                    <Route index element={<RequirePerm perm={permissions.includes('weakSignalCategories.view')}><WeakSignalCategory /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('weakSignalCategories.update')}><UpdateWeakSignalCategory /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- WEAK SIGNALS -------------------- */}
            <Route path="weak-signals">
                <Route index element={<RequirePerm perm={permissions.includes('weakSignals.viewAny')}><WeakSignals /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={permissions.includes('weakSignals.create')}><CreateWeakSignal /></RequirePerm>} />
                <Route path=":id">
                    <Route index element={<RequirePerm perm={permissions.includes('weakSignals.view')}><WeakSignal /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={permissions.includes('weakSignals.update')}><UpdateWeakSignal /></RequirePerm>} />
                </Route>
            </Route>
        </Routes>
    );
}
