import { useEffect, Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// MUI
import { Backdrop, CircularProgress } from '@mui/material';

// Apollo
import { useQuery } from '@apollo/client';
import { USER } from 'views/authenticated/management/users/user/gql-user';

// Services
import { IUser } from 'services/interfaces';
import { handleLocaleManagement } from 'services/helpers';
import { getStoredUser, setStoredUser, setPermissions } from 'services/storage';

// Components
import Header from 'components/header';
import Footer from 'components/footer';
import Spinner from 'components/spinner';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- AUTHENTICATED LAYOUT -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function AuthenticatedLayout() {

    const storedUser = getStoredUser();
    const { state } : any = useLocation();

    const { loading, data } = useQuery<{ user: IUser }>(USER, {
        variables: { id: storedUser?.id },
        skip: state?.skipFetchUser,
    });

    useEffect(() => {
        if (data?.user) {

            let user : any = {...data.user};
            setPermissions(user.permissions);

            delete user.permissions;
            setStoredUser(user);
            
            handleLocaleManagement(user.locale);

            const event = new Event('updateAvatar');
            document.dispatchEvent(event);
        }
    }, [data]);

    if (loading) return (
        <Backdrop
            open
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );

    return (
        <>
            <Header authenticated />
            <Suspense fallback={<Spinner />}>
                <Outlet />
            </Suspense>
            <Footer authenticated />
        </>
    );
}
