import i18next from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

// MUI
import { frFR, deDE, enUS } from '@mui/x-date-pickers';

// Services
import { IModelTranslation, ISelectOptionWithBeneficiaryAttributes, ISelectOptionWithUserAttributes } from './interfaces';
import { alphabetChars, originLabel } from './constants';

// Components
import { isValidPhoneNumber } from 'components/react-international-phone';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- GET ADAPTER LOCALE ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getAdapterLocale = () => {

    switch (i18next.language.substring(0, 2)) {

        case 'fr':
            return 'fr';

        case 'de':
            return 'de';

        default:
            return 'en';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- GET LOCALE TEXT ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getLocaleText = () => {

    switch (i18next.language.substring(0, 2)) {

        case 'fr':
            return frFR.components.MuiLocalizationProvider.defaultProps.localeText;

        case 'de':
            return deDE.components.MuiLocalizationProvider.defaultProps.localeText;

        default:
            return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- TRUNCATE LABEL ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const truncateLabel = (label: string, index: number) => {
    if (label.length > index) {
        return `${label.substring(0, index).trimEnd()}...`;
    }
    return label;
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SET DOCUMENT TITLE --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const setDocumentTitle = (title: string) => {
    document.title = `${truncateLabel(title, 48)} | ${originLabel}`;
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- LOCALES --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getLocales = () => [
    {
        value: 'fr',
        label: i18next.t('locale.fr'),
        notTranslated: 'Français',
    },
    {
        value: 'en',
        label: i18next.t('locale.en'),
        notTranslated: 'English',
    },
];

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- HANDLE LOCALE MANAGEMENT ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const handleLocaleManagement = (locale: string | undefined) => {
    if (locale) {
        localStorage.setItem('locale', locale);
        i18next.changeLanguage(locale);
        dayjs.locale(locale);
    }
};

// --------------------------------------------------------------------------------- \\
// ----------------------------- GET ERROR EXTENSIONS  ----------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getErrorExtensions = (error: any) => {
    let hasErrorExtension = false;
    let message = '';

    for (let err of error.graphQLErrors) {
        let item = '';

        if (err?.extensions?.validation && Object.values(err?.extensions?.validation)?.length > 0) {
            hasErrorExtension = true;

            Object.values(err.extensions.validation).forEach((value: any) => {
                value.forEach((v: any) => item += v);
            });
        }
        message += item;
    }

    if (hasErrorExtension) {
        return message;
    }
    return error?.message;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- IS VALID EMAIL ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const isValidEmail = (email: string) => {
    // eslint-disable-next-line
    let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g;
    return regex.test(email);
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ EMAIL HELPER TEXT --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getEmailHelperText = (email: string, phoneNumber: string) => {
    if (email !== '' && !isValidEmail(email)) {
        return i18next.t('invalid_email');
    }
    if (email === '' && !isValidPhoneNumber(phoneNumber)) {
        return i18next.t('please_enter_mail_or_phone');
    }
};

// --------------------------------------------------------------------------------- \\
// ----------------------------- CHECK PHONE VALIDITY ------------------------------ \\
// --------------------------------------------------------------------------------- \\

export const checkPhoneValidity = (phone: string) => {
    // eslint-disable-next-line
    let regex = /^\+[1-9]{1}[0-9]{3,14}$/;
    return regex.test(phone);
};

// --------------------------------------------------------------------------------- \\
// --------------------------------- GET BACK URL ---------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getBackUrl = (url: string, beforeLast?: boolean) => {
    let index = url.lastIndexOf('/');
    if (beforeLast) {
        let secondIndex = url.lastIndexOf('/', index - 1);
        return url.slice(0, secondIndex);
    }
    if (index !== -1) {
        return url.slice(0, index);
    }
    return url;
};

// --------------------------------------------------------------------------------- \\
// ------------------------------ GET FORMATTED DATE ------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getFormattedDate = (date: Dayjs | string | undefined) => {
    if (date) {
        return dayjs(date).format('DD/MM/YYYY');
    }
    return date;
};

// --------------------------------------------------------------------------------- \\
// ---------------------------- GET FORMATTED DATE TIME ---------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getFormattedDateTime = (date: Dayjs | string | undefined) => {
    if (date) {
        return dayjs(date).format('DD/MM/YYYY HH:mm');
    }
    return date;
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- GET FORMATTED TIME ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getFormattedTime = (time?: string) => {
    if (time) {
        return time.substring(0, 5).replace(':', 'h');
    }
    return '';
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- TRANSFORM TIME TO DATE -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const transformTimeToDate = (time: string) => {
    if (time && time.length >= 5) {
        return dayjs().hour(Number(time.substring(0, 2))).minute(Number(time.substring(3, 5)));
    }
    return null;
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- CAPITALIZE ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const toCapitalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

// --------------------------------------------------------------------------------- \\
// --------------------------------- GET ROLE LABEL -------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getRoleLabel = (roleId: string | undefined) => {

    switch (roleId) {

        case '1':
            return i18next.t('super_admin');

        case '2':
            return i18next.t('admin');

        case '3':
            return i18next.t('manager');

        case '4':
            return i18next.t('trainer');

        case '5':
            return i18next.t('learner');

        default:
            return i18next.t('none.1');
    }
};

// --------------------------------------------------------------------------------- \\
// ----------------------------- CONVERT IMG TO BASE 64 ---------------------------- \\
// --------------------------------------------------------------------------------- \\

export const convertImgToBase64URL = (url: string) => {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.crossOrigin = 'Anonymous';

        img.onload = () => {
            var canvas = document.createElement('CANVAS') as any;
            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            var ctx = canvas.getContext('2d');
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0);
            resolve({ imgData: canvas.toDataURL('image/jpeg'), width: canvas.width, height: canvas.height });
            canvas = null; 
        };

        img.onerror = () => {
            reject('Image not found.');
        };

        img.src = url;
    });
};

// --------------------------------------------------------------------------------- \\
// ------------------------------ ASPECT RATIO JSPDF ------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const calculateAspectRatioFit = (srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { resizedWidth: (srcWidth * ratio), resizedHeight: (srcHeight * ratio) };
};

// --------------------------------------------------------------------------------- \\
// ------------------------------ GET FULL ADDRESS --------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getFullAddress = (object: any) => {
    let array = [];
    if (object?.address) {
        array.push(object.address);
    }
    if (object?.postalCode) {
        array.push(object.postalCode);
    }
    if (object?.city) {
        array.push(object.city);
    }
    if (object?.country) {
        array.push(object.country?.name);
    }
    return array.join(' ');
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- GET APPLICATION NAME ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getApplicationName = (name: string) => {

    switch (name) {

        case 'zerobarrier':
            return 'Zero Barrier';

        case 'happinco':
            return 'HappIn\'Co';

        case 'mealsAndBenefits':
            return window.origin !== 'https://repasetbienfaits.app'
                ? "Compani'On Home"
                : 'Repas et Bienfaits';

        default:
            return name;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- CONNECT OR CREATE USER -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const connectOrCreateUser = (user: ISelectOptionWithUserAttributes) => {
    if (user?.value == null) {
        return ({
            create: {
                lastName: user?.lastName,
                firstName: user?.firstName,
                gender: user?.gender,
                birthdate: user?.birthdate,
                email: user?.email || null,
                phoneNumberPrefix: user?.phoneNumberPrefix,
                phoneNumber: user?.phoneNumber,
            }
        });
    }
    return ({ connect: user.value });
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- CONNECT OR CREATE BENEFICIARY ---------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const connectOrCreateBeneficiary = (beneficiary: ISelectOptionWithBeneficiaryAttributes) => {
    if (beneficiary?.value == null) {
        return ({
            create: {
                lastName: beneficiary?.lastName,
                firstName: beneficiary?.firstName,
                birthdate: beneficiary?.birthdate,
                address: beneficiary?.address,
                addressDetails: beneficiary?.addressDetails,
                postalCode: beneficiary?.postalCode,
                city: beneficiary?.city,
                country: beneficiary?.countryId ? { connect: beneficiary.countryId } : null,
                company: beneficiary?.companyId ? { connect: beneficiary.companyId } : null,
            }
        });
    }
    return ({ connect: beneficiary.value });
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ GET DEFAULT TRANSLATIONS -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getDefaultTranslations = (translations: IModelTranslation[] | undefined) => {

    const locales = getLocales();

    return locales.map(l => {

        let translation = translations?.find(t => t.locale === l.value);
        
        return ({
            locale: l.value,
            id: translation?.id || undefined,
            name: translation?.name || '',
        });
    });
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ GET GENDER ICON ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getGenderIcon = (gender: string) => {

    switch (gender) {

        case 'MALE':
            return 'man';

        case 'FEMALE':
            return 'woman';

        default:
            return undefined;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- EXCEL COLUMN FROM INDEX ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getExcelColumnFromIndex = (index: number) : string => {
    let value = index / 26;

    if (value >= 1) {
        return getExcelColumnFromIndex(Math.floor(value - 1)) + alphabetChars[index % 26];
    }
    return alphabetChars[index % 26];
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- GET BASE URI ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getDataUri = (url: string, callback: (dataUri: string, imgWidth: number, imgHeight: number) => void) => {

    return new Promise((resolve, reject) => {

        let image = new Image();
        image.crossOrigin = 'Anonymous';
        image.src = url;

        image.onload = function() {
        
            let canvas = document.createElement('canvas');
            canvas.width = image.naturalWidth;
            canvas.height = image.naturalHeight;
    
            let ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.fillStyle = '#FFFFFF';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                resolve(callback(canvas.toDataURL('image/png'), canvas.width, canvas.height));
            }
        };

        image.onerror = function(e) {
            reject(e);
        };
    });
};
