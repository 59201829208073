import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

// Services
import PACKAGE_JSON from '../package.json';

// Routes
import PrivateRoutes from 'routes/private-routes';
import PublicRoutes from 'routes/public-routes';

// Components
import Cookies from 'components/cookies';
import AppLayout from 'components/app-layout';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------------ APP ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const App = () => {

    const APP_VERSION = localStorage.getItem('APP_VERSION');

    useEffect(() => {
        
        if (window.origin.includes('repasetbienfaits')) {
            document.title = "Repas et Bienfaits"
        }

        /* * *
        * Clear CacheStorage when there is a new version of build in package.json
        */
        if (APP_VERSION !== PACKAGE_JSON.version) {

            localStorage.setItem('APP_VERSION', PACKAGE_JSON.version);

            if (caches) {
                caches.keys().then(keys => {
                    keys.forEach(key => {
                        caches.delete(key);
                    });
                    window.location.reload();
                });
            }
            else {
                window.location.reload();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Cookies />
            <AppLayout>
                <Routes>
                    <Route path="/app/*" element={<PrivateRoutes />} />
                    <Route path="/*" element={<PublicRoutes />} /> 
                </Routes>
            </AppLayout>
            <div className="layoutBackground" />
        </>
    );
};

export default App;
