import { useEffect } from 'react';
import { setDocumentTitle } from './helpers';
import useMediaQuery from '@mui/material/useMediaQuery';

// --------------------------------------------------------------------------------- \\
// ------------------------------- DOCUMENT TITLE ---------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const useDocumentTitle = (title: string) => {
    useEffect(() => {
        setDocumentTitle(title);
    }, [title]);
};

// --------------------------------------------------------------------------------- \\
// -------------------------------- MEDIA QUERIES ---------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const useMediaQueries = () => {
    return {
        mediaXL: useMediaQuery('(min-width:1536px)'),
        mediaLG: useMediaQuery('(min-width:1200px)'),
        mediaMD: useMediaQuery('(min-width:900px)'),
        mediaSM: useMediaQuery('(min-width:600px)'),
        mediaXS: useMediaQuery('(min-width:0px)'),
        mediaDesktop: useMediaQuery('(pointer:fine)'),
    };
};
