import { lazy } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// Services
import { getStoredToken } from 'services/storage';

// Views
import UnauthenticatedLayout from 'views/unauthenticated';

// Components
import PageNotFound from 'components/page-not-found';

/* * *
* Lazy Imports
*/

const Login = lazy(() => import('../../views/unauthenticated/login'));
const PasswordForgot = lazy(() => import('../../views/unauthenticated/password-forgot'));
const SetNewPassword = lazy(() => import('../../views/unauthenticated/set-new-password'));
const ApiResponse = lazy(() => import('../../views/unauthenticated/api-response'));
const LegalNotice = lazy(() => import('../../views/unauthenticated/legal-notice'));

/* * *
* Redirect to App
*/

const RedirectToApp = ({ children } : { children: JSX.Element }) => {

    const token = getStoredToken();

    if (token) {
        return <Navigate replace to="/app" />;
    }
    return children;
};

/* * *
* Redirect to Intervention
*/

const RedirectToIntervention = () => {

    const token = getStoredToken();
    const { search } = useLocation();
    const interventionId = new URLSearchParams(search).get('id');

    if (token && interventionId) {
        return <Navigate to={`/app/interventions/${interventionId}`} />;
    }
    return <Navigate to="/" state={{ interventionId }} />;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- PUBLIC ROUTES ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function PublicRoutes() {
    return (
        <Routes>
            <Route element={<RedirectToApp><UnauthenticatedLayout /></RedirectToApp>}>
                <Route index element={<Login />} />
                <Route path="password-forgot" element={<PasswordForgot />} />
                <Route path="set-new-password" element={<SetNewPassword />} />
                <Route path="api-response" element={<ApiResponse />} />
                <Route path="interventions" element={<RedirectToIntervention />} />
                <Route path="legal-notice" element={<LegalNotice />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    );
}
