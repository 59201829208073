import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// CSS
import styles from './Footer.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- FOOTER ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const Footer = ({ authenticated = false } : { authenticated?: boolean }) => {

    const { t } = useTranslation();

    return (
        <div className={styles.footer}>
            <span>
                Copyright © 2022-{new Date().getFullYear()} Discovia. {t('all_rights_reserved')}.
            </span>
            {!authenticated && (
                <Link
                    to="/legal-notice"
                    className={styles.legalNotice}
                >
                    {t('legal_notice')}.
                </Link>
            )}
        </div>
    );
};

export default Footer;
