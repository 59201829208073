import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI
import { IconButton, Avatar, Tooltip } from '@mui/material';

// Services
import { getStoredUser } from 'services/storage';
import { API_URL } from 'services/apollo-config';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- AVATAR ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const CustomizedAvatar = () => {

    const { t } = useTranslation();
    const [photoUrl, setPhotoUrl] = useState(getStoredUser()?.photoUrl);

    useEffect(() => {

        const onUpdateAvatar = () => {
            setPhotoUrl(getStoredUser()?.photoUrl);
        };
        document.addEventListener('updateAvatar', onUpdateAvatar);

        return () => {
            document.removeEventListener('updateAvatar', onUpdateAvatar);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <IconButton
            component={Link}
            to="/app/profile"
        >
            <Tooltip title={t('profile')}>
                <Avatar
                    src={photoUrl ? (API_URL + photoUrl) : undefined}
                    sx={{ border: '2px solid white' }}
                />
            </Tooltip>
        </IconButton>
    );
};

export default CustomizedAvatar;
