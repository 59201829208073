import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Services
import { getPermissions } from 'services/storage';

// Views
import { RequirePerm } from '../index';

/* * *
* Lazy Imports
*/

// Interventions
const Interventions = lazy(() => import('../../../views/authenticated/interventions'));
const Intervention = lazy(() => import('../../../views/authenticated/interventions/intervention'));
const CreateIntervention = lazy(() => import('../../../views/authenticated/interventions/create-intervention'));
const UpdateIntervention = lazy(() => import('../../../views/authenticated/interventions/update-intervention'));

// Meal Questionnaires
const CreateMealQuestionnaire = lazy(() => import('../../../views/authenticated/meal-questionnaires/create-meal-questionnaire'));
const UpdateMealQuestionnaire = lazy(() => import('../../../views/authenticated/meal-questionnaires/update-meal-questionnaire'));

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- INTERVENTION ROUTES ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function InterventionRoutes() {

    const permissions = getPermissions() || [];

    return (
        <Routes>
            <Route index element={<RequirePerm perm={permissions.includes('interventions.viewAny')}><Interventions /></RequirePerm>} />
            <Route path="create" element={<RequirePerm perm={permissions.includes('interventions.create')}><CreateIntervention /></RequirePerm>} />
            <Route path=":interventionId">
                <Route index element={<RequirePerm perm={permissions.includes('interventions.view')}><Intervention /></RequirePerm>} />
                <Route path="update" element={<RequirePerm perm={permissions.includes('interventions.update')}><UpdateIntervention /></RequirePerm>} />
                <Route path="create-meal-questionnaire" element={<RequirePerm perm={permissions.includes('interventions.update')}><CreateMealQuestionnaire /></RequirePerm>} />
                <Route path="update-meal-questionnaire/:mealQuestionnaireId" element={<RequirePerm perm={permissions.includes('interventions.update')}><UpdateMealQuestionnaire /></RequirePerm>} />
            </Route>
        </Routes>
    );
}
