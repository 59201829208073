import { useCallback, useEffect } from 'react';

// Services
import { IAgeRange } from './interfaces';

// --------------------------------------------------------------------------------- \\
// -------------------------------- ORIGIN LABEL ----------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const originLabel = window.origin.includes('repasetbienfaits') ? "Repas et Bienfaits" : "Compani'On Home";

// --------------------------------------------------------------------------------- \\
// --------------------------------- MUI COLORS ------------------------------------ \\
// --------------------------------------------------------------------------------- \\

export const yellowLight = '#DBE572';
export const yellowMain = '#CFCF28';
export const yellowDark = '#B1BE3E';

export const magentaLight = '#E7679B';
export const magentaMain = '#E7307B';
export const magentaDark = '#BA2763';

export const blueLight = '#1C7CB6';
export const blueMain = '#005A86';
export const blueDark = '#0A3149';

// --------------------------------------------------------------------------------- \\
// ------------------------- FORBIDDEN KEYS NUMBER INPUT --------------------------- \\
// --------------------------------------------------------------------------------- \\

export const forbiddenKeysNumberInput = ['-', '+', 'e'];

// --------------------------------------------------------------------------------- \\
// ------------------------------ FORBIDDEN KEYS EXCEL ----------------------------- \\
// --------------------------------------------------------------------------------- \\

export const forbiddenKeysExcel = ['*', '?', ':', '/', '\'', '[', ']'];

// --------------------------------------------------------------------------------- \\
// --------------------------------- AGE RANGES ------------------------------------ \\
// --------------------------------------------------------------------------------- \\

export const ageRanges : IAgeRange[] = [
    {
        ageMin: 50,
        ageMax: 60,
    },
    {
        ageMin: 61,
        ageMax: 70,
    },
    {
        ageMin: 71,
        ageMax: 80,
    },
    {
        ageMin: 81,
        ageMax: 90,
    },
    {
        ageMin: 91,
        ageMax: 100,
    },
];

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ALPHABET CHARS ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const alphabetChars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

// --------------------------------------------------------------------------------- \\
// ----------------------------------- LOCALES ------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const locales = [
    {
        value: 'fr',
        label: 'french',
    },
    {
        value: 'en',
        label: 'english',
    },
];

// --------------------------------------------------------------------------------- \\
// --------------------------------- SCROLL DATA ----------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const useScrollData = ({
    state,
    setState,
    dependency
}: ({
    state: any,
    setState: React.Dispatch<React.SetStateAction<any>>,
    dependency: any,
})) => {

    const onReachEnd = useCallback(() => {
        let layout = document.getElementById('root');
        let scrollTop = layout?.scrollTop;
        let innerHeight = window.innerHeight;
        let scrollHeight = layout?.scrollHeight;

        if ((state.page * state.first < state.total) && (scrollTop && scrollHeight) && (scrollTop + innerHeight >= scrollHeight)) {
            layout?.removeEventListener('scroll', onReachEnd);
            setState((prevState: any) => ({ ...prevState, loading: true, page: prevState.page += 1 }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependency]);

    useEffect(() => {
        
        let layout = document.getElementById('root');
        layout?.addEventListener('scroll', onReachEnd);

        return () => {
            layout?.removeEventListener('scroll', onReachEnd);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependency]);
};
