import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Services
import { getPermissions } from 'services/storage';

// Views
import { RequirePerm } from '../index';

/* * *
* Lazy Imports
*/

// Beneficiaries
const Beneficiaries = lazy(() => import('../../../views/authenticated/beneficiaries'));
const Beneficiary = lazy(() => import('../../../views/authenticated/beneficiaries/beneficiary'));
const BeneficiaryInterventions = lazy(() => import('../../../views/authenticated/beneficiaries/beneficiary/beneficiary-interventions'));
const CreateBeneficiary = lazy(() => import('../../../views/authenticated/beneficiaries/create-beneficiary'));
const UpdateBeneficiary = lazy(() => import('../../../views/authenticated/beneficiaries/update-beneficiary'));

// Meal Questionnaires
const MealQuestionnaires = lazy(() => import('../../../views/authenticated/meal-questionnaires'));
const CreateMealQuestionnaire = lazy(() => import('../../../views/authenticated/meal-questionnaires/create-meal-questionnaire'));
const UpdateMealQuestionnaire = lazy(() => import('../../../views/authenticated/meal-questionnaires/update-meal-questionnaire'));

// Interventions
const Intervention = lazy(() => import('../../../views/authenticated/interventions/intervention'));
const UpdateIntervention = lazy(() => import('../../../views/authenticated/interventions/update-intervention'));

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- BENEFICIARY ROUTES ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function BeneficiaryRoutes() {

    const permissions = getPermissions() || [];

    return (
        <Routes>
            <Route index element={<RequirePerm perm={permissions.includes('beneficiaries.viewAny')}><Beneficiaries /></RequirePerm>} />
            <Route path="create" element={<RequirePerm perm={permissions.includes('beneficiaries.create')}><CreateBeneficiary /></RequirePerm>} />
            <Route path=":beneficiaryId">
                <Route index element={<RequirePerm perm={permissions.includes('beneficiaries.view')}><Beneficiary /></RequirePerm>} />
                <Route path="update" element={<RequirePerm perm={permissions.includes('beneficiaries.update')}><UpdateBeneficiary /></RequirePerm>} />
                <Route path="interventions">
                    <Route index element={<RequirePerm perm={permissions.includes('interventions.viewAny')}><BeneficiaryInterventions /></RequirePerm>} />
                    <Route path=":interventionId">
                        <Route index element={<RequirePerm perm={permissions.includes('interventions.view')}><Intervention /></RequirePerm>} />
                        <Route path="update" element={<RequirePerm perm={permissions.includes('interventions.update')}><UpdateIntervention /></RequirePerm>} />
                        <Route path="create-meal-questionnaire" element={<RequirePerm perm={permissions.includes('interventions.update')}><CreateMealQuestionnaire /></RequirePerm>} />
                        <Route path="update-meal-questionnaire/:mealQuestionnaireId" element={<RequirePerm perm={permissions.includes('interventions.update')}><UpdateMealQuestionnaire /></RequirePerm>} />
                    </Route>
                </Route>
                <Route path="meal-questionnaires" element={<MealQuestionnaires />} />
            </Route>
        </Routes>
    );
}
